import React from "react";
import "./styles/labeler.scss";
import {ApiEngine} from "api-engine";
import LabelCard from "./models/LabelCard";
import AddLabelForm from "./components/labels/AddLabelForm";
import {LabelCardsViewMode} from "./components/labels/enums/LabelCardsViewMode";
import PhotoDisplayClass from "./components/photo_displays/PhotoDisplayClass";
import LabelsList from "./components/labels/LabelsList";

interface LabelerProps {
  api: ApiEngine
  project: any
  dataSource: any
  dataSourceFile: any
  dataLoading: boolean
}

interface LabelerState {
  labels: any[]
  videoWidth: number | null
  videoHeight: number | null,
  videoLength: number,
  currentTime: number
  // labelCardsList: LabelCard[]
  currentRectangleCoordinates: number[]
  blockActions: boolean
  error: any
  viewMode: LabelCardsViewMode
  hoveredTitle: string | null
  dataLoading: boolean
}

function labelsSortingFunc(_a: any, _b: any): number {
  if (_a.time_frame === _b.time_frame) {
    return 0;
  }
  return _a.time_frame > _b.time_frame ? 1 : -1;
}

export default class LabelerClass extends React.Component<LabelerProps, LabelerState> {
  mounted = false
  private photoDisplayRef = React.createRef<PhotoDisplayClass>();
  private videoRef = React.createRef<HTMLVideoElement>();
  private controlLineRef= React.createRef<HTMLDivElement>();

  constructor(props: LabelerProps) {
    super(props);

    this.state = {
      labels: this.props.dataSourceFile.labels.sort(labelsSortingFunc),
      currentTime: 0,
      videoWidth: null,
      videoHeight: null,
      videoLength: 0,
      // labelCardsList: [],
      currentRectangleCoordinates: [],
      blockActions: false,
      error: null,
      viewMode: LabelCardsViewMode.ALL,
      hoveredTitle: null,
      dataLoading: this.props.dataLoading
    }
    this.handleRectangleSelect = this.handleRectangleSelect.bind(this);
    this.handleRectangleEdit = this.handleRectangleEdit.bind(this);
    this.handleCancelEdit = this.handleCancelEdit.bind(this);
    this.photoDisplayResizeCallbackOnMount = this.photoDisplayResizeCallbackOnMount.bind(this);
    this.addLabelCard = this.addLabelCard.bind(this);
    this.deleteLabelCard = this.deleteLabelCard.bind(this);
    this.handleSaveLabel = this.handleSaveLabel.bind(this);
    this.convertLabelToLabelCard = this.convertLabelToLabelCard.bind(this);
    this.convertLabelToCoordinates = this.convertLabelToCoordinates.bind(this);
    this.labelsAsLabelCards = this.labelsAsLabelCards.bind(this);
    this.labelsAsRectangleCoordinates = this.labelsAsRectangleCoordinates.bind(this);
    this.onHover = this.onHover.bind(this);
    this.restoreWidthAndHeight = this.restoreWidthAndHeight.bind(this);
    this.setTime = this.setTime.bind(this);
  }

  componentDidMount() {
    if (this.mounted) return;
    this.mounted = true;
    this.restoreWidthAndHeight();
  }

  restoreWidthAndHeight() {
    if (!this.videoRef.current) {
      setTimeout(this.restoreWidthAndHeight, 2000);
      return
    }
    if (!this.videoRef.current) {
      setTimeout(this.restoreWidthAndHeight, 2000);
      return;
    }
    if (!this.videoRef.current.duration) {
      setTimeout(this.restoreWidthAndHeight, 2000);
      return;
    }
    this.setState({
      videoWidth: this.videoRef.current.getBoundingClientRect().width,
      videoHeight: this.videoRef.current.getBoundingClientRect().height,
      videoLength: this.videoRef.current.duration
    })
  }

  convertLabelToLabelCard(_label: any, width: number, height: number) {
    return {
      id: _label.id,
      title: _label.title,
      time_frame: _label.time_frame,
      coordinates:
        [ _label.x0,
          _label.y0,
          _label.x1,
          _label.y1,
          _label.time_frame
        ]
    }
  }

  convertLabelToCoordinates(_label: any, width: number, height: number) {
    // const res = [_label.x0 * width, _label.y0 * height, _label.x1 * width , _label.y1 * height, _label.time_frame ];
    const res = [_label.x0, _label.y0, _label.x1, _label.y1, _label.time_frame, _label.title ];
    return res;
  }

  photoDisplayResizeCallbackOnMount(width: number, height: number) {
    const me = this;
    me.setState({
      videoWidth: width,
      videoHeight: height,
    });
  }

  handleRectangleSelect(startX: number, startY: number, endX: number, endY: number, timeFrame?: number) {
    this.setState({
      currentRectangleCoordinates: [startX, startY, endX, endY, timeFrame!],
    }, () => {

    });
  };

  addLabelCard(newLabelCard: LabelCard, rectangleCoordinates: number[]): Promise<void> {
    const me = this;

    return new Promise((resolve, reject) => {
      if (!me.state.videoWidth || !me.state.videoHeight) {
        alert("Не заданы параметры изображения")
        reject();
        return;
      }
      newLabelCard.coordinates = rectangleCoordinates;
      const url = `/api/projects/data_sources/data_source_files/${me.props.dataSourceFile.id}/labels/new`;
      // alert(`${width} x ${height}`)
      let dataToSend = {
        title: newLabelCard.title,
        project: {id: me.props.project.id},
        dataSource: {id: me.props.dataSource.id},
        dataSourceFile: {id: me.props.dataSourceFile.id},
        x0: rectangleCoordinates[0],
        y0: rectangleCoordinates[1],
        x1: rectangleCoordinates[2],
        y1: rectangleCoordinates[3],
        timeFrame: rectangleCoordinates[4]
      };
      me.props.api.asyncFetch(url, {method: "POST", body: JSON.stringify(dataToSend)}).then((_res) => {
        // document.location.reload();
        me.setState({
          currentRectangleCoordinates: [],
          labels: [...me.state.labels, _res].sort(labelsSortingFunc),
        }, () => {
          resolve();
        });
      });
    });
  };

  labelsAsLabelCards(labels: any[]) : LabelCard[] {
    const me = this;
    if (!me.state.videoWidth || !me.state.videoHeight) return [];
    const res =  labels.map((_x: any) => {
      return this.convertLabelToLabelCard(_x, me.state.videoWidth!!, me.state.videoHeight!!)
    })
    // console.log(res);
    return res;
  }

  labelsAsRectangleCoordinates(labels: any[]) : number[][] {
    const me = this;
    if (!me.state.videoWidth || !me.state.videoHeight) return [];
    return labels.map((_label: any) => {
      return me.convertLabelToCoordinates(_label, me.state.videoWidth!!, me.state.videoHeight!!);
    });
  }

  deleteLabelCard(card: any) {
    const me = this;
    const url = `/api/projects/data_sources/data_source_files/${me.props.dataSourceFile.id}/labels/${card.id}/delete`;
    me.props.api.asyncFetchWithoutQueing(url,{}).then((_res) => {
      if (!_res) {
        alert("Не удалось удалить");
        return;
      }
      const myIndex = me.state.labels.map((x) => {
        return x.id
      }).indexOf(card.id);
      let newLabels = [...me.state.labels];
      newLabels.splice(myIndex, 1);
      // let newLabelsCards = [...me.state.labelCardsList];
      // newLabelsCards.splice(myIndex, 1)
      // let allRectangleCoordinates = [...me.state.allRectangleCoordinates];
      // allRectangleCoordinates.splice(myIndex, 1)
      me.setState({
        labels: newLabels,
        // labelCardsList: newLabelsCards,
        // allRectangleCoordinates: allRectangleCoordinates
      });
    })
  };

  handleRectangleEdit(index: number) {
    const me = this;
    // this.setState({
    //   // currentRectangleCoordinates: me.state.allRectangleCoordinates[index],
    // })
  };

  handleSaveLabel(editedLabelCard: LabelCard, editedCoordinates: number[]) {
    // const me = this;
    // if (me.state.index === null) {return}
    // const updatedLabelCardsList = [...me.state.labelCardsList];
    // updatedLabelCardsList[me.state.index] = editedLabelCard;
    //
    // const updatedAllRectangleCoordinates = [...me.state.allRectangleCoordinates];
    // updatedAllRectangleCoordinates[me.state.index] = editedCoordinates;
    //
    // me.setState({
    //   labelCardsList: updatedLabelCardsList,
    //   allRectangleCoordinates: updatedAllRectangleCoordinates,
    //   index: null
    // })
  }

  handleCancelEdit() {

  };

  onHover(_title: string) {
    if (_title === this.state.hoveredTitle) {
      console.log(`Not changing hover, because equal`);
      return;
    }
    this.setState({
      hoveredTitle: _title
    });
  }

  setTime(_time: number) {
    const me = this;
    if (me.state.currentTime === _time) return;
    me.setState({currentTime: _time}, () => {
      me.videoRef.current!!.currentTime = _time!!;
    })
  }

  render() {
    const me = this;
    let imageUrl = me.props.dataSourceFile.file_url;
    if (!imageUrl) return <p>Загрузка данных</p>
    imageUrl = imageUrl.indexOf("http") > -1 ? imageUrl.replace(/([^:]\/)\/+/g, "$1") : `${me.props.api.serverUrl}/${imageUrl.replace(/([^:]\/)\/+/g, "$1")}`;
    return <div className={"labeler"}>
      <div className="left-column" >
        {/*<span style={{display: "none"}}>{me.state.hoveredTitle}*/}
        {/*  {JSON.stringify(me.state.labels)}</span>*/}
        {/*<p>{me.state.currentTime}</p>*/}
        {/*{JSON.stringify(me.state.labelCardsList)}*/}
        {/*<p>{JSON.stringify(me.state.currentRectangleCoordinates)}</p>*/}

      <div style={{position: "relative"}}>
        <PhotoDisplayClass
          ref={this.photoDisplayRef}
          width={me.state.videoWidth}
          height={me.state.videoHeight}
          duration={me.state.videoLength}
          changeCurrentTime={(_newTime: number) => {
            if (me.state.currentTime === _newTime) return;
            me.setState({currentTime: _newTime}, () => {
              me.videoRef.current!!.currentTime = _newTime!!;
            })
          }}
          key={`${me.state.hoveredTitle}-${me.state.videoLength}-${me.state.currentTime}-${JSON.stringify(me.state.labels)}`}
          labels={me.labelsAsLabelCards(me.state.labels)}
          hoveredLabelTitle={me.state.hoveredTitle}
          initialCurrentTime={me.state.currentTime}
          callback={me.photoDisplayResizeCallbackOnMount }
          imageUrl={imageUrl}
          onRectangleSelect={me.handleRectangleSelect}
          allRectangleCoordinates = {me.labelsAsRectangleCoordinates(me.state.labels)}
          setAllRectangleCoordinates = {(_allRectangleCoordinates: any) => {
              // me.setState(_allRectangleCoordinates)
            }
          }
        />
        
          <video
            ref={me.videoRef}
            controls={false}
            src={imageUrl}
            style={{zIndex: 0, width: "57vw",  height: "auto", pointerEvents: "none"}}
          ></video>

        
      </div>
        <div className={"control-line mb-20"}
             ref={me.controlLineRef}
             onClick={(_ev: React.MouseEvent<HTMLDivElement>) => {
               _ev.stopPropagation();
               const x = (_ev.clientX - me.controlLineRef.current!.getBoundingClientRect().x) / me.controlLineRef.current!.getBoundingClientRect().width;
               // me.props.callback(me.imageRef.current!!.getBoundingClientRect().width, me.imageRef.current!!.getBoundingClientRect().height);
               me.setState({
                 currentTime: x * me.state.videoLength
               }, () => {
                 me.videoRef.current!!.currentTime!! = x * me.state.videoLength;
               })
             }}
        >
          { (me.state.videoLength > 0) && <div
              style={{
                position: "relative",
                background: "var(--blue_400_alpha20)",
                borderRight: "1px solid var(--color-primary)",
                width: `${100.0 * me.state.currentTime / me.state.videoLength}%`
              }}>
              <div style={{
                position: "absolute",
                right: me.state.currentTime / me.state.videoLength < 0.1 ? undefined : "-1px",
                top: "100%",
                background: "inherit",
                fontSize: "8px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                alignContent: "center",
                padding: "5px",
                borderRadius: "0 0 9px 9px",
                borderRight: "1px solid var(--color-primary)",

              }}>
                <i className={"fal fa-clock mr-5"}/>{Math.round(me.state.currentTime * 100) / 100}&nbsp;с
              </div>
          </div> }
          { me.state.labels.length > 0 &&
            me.state.labels.map((x: any) => {return x.time_frame}).
            filter(function (v, i, self) {
              return i === self.indexOf(v);
            }).
            map((time, index) => {
              const labelsInside = me.state.labels.filter((y) => {
                return y.time_frame === time
              }).map((xx) => {return xx.title;}).
              filter(function (v, i, self) {
                return i === self.indexOf(v);
              });
              let hovered = labelsInside.filter((label) => {
                return label === me.state.hoveredTitle;
              }).length > 0;

              // console.log(rectangle[4]);
              return <div className={"point"}
                          key={`rectangle-${index}`}
                          style={me.videoRef.current ? {
                            background: hovered ? "var(--red)" : undefined,
                            zIndex: hovered ? 15 : 0,
                            left: `${100 * time / me.state.videoLength}%`,
                            opacity: 1,
                            position: "absolute"
                          } : {opacity: 0}}
                          onClick={() => {
                            me.setState({
                              currentTime: time
                            }, () => {
                              me.videoRef.current!!.currentTime!! = time;
                            });
                          }}
              >
                <div style={{zIndex: 5, top: "100%"}}
                     className={"helper"}>{labelsInside.map((xx) => { return <span className={"caramel"}>{xx}</span>})}</div>
              </div>
            })
          }
        </div>
    </div>
    <div className="right-column">
      <h5>Размеченные данные</h5>
      { imageUrl && (
        <AddLabelForm
          active={me.state.currentRectangleCoordinates.length > 0}
          project={me.props.project}
          dataSource={me.props.dataSource}
          dataSourceFile={me.props.dataSourceFile}
          labels={me.state.labels}
          addLabelCard={me.addLabelCard}
          currentRectangleCoordinates={me.state.currentRectangleCoordinates}
        />
      )}
      {me.state.dataLoading ? <p>Разметка загружается</p> :
        <>
          <LabelsList api={me.props.api}
                      key={JSON.stringify(me.state.labels)}
                      labels={me.state.labels}
                      deleteLabelCard={me.deleteLabelCard}
                      rectangleCoordinates={me.labelsAsRectangleCoordinates(me.state.labels)}
                      addLabelCard={me.addLabelCard}
                      onHover={me.onHover}
                      setTime={me.setTime}
          />

          {me.state.error &&
              <p style={{color: "var(--red)"}}>{me.state.error}</p>
          }
          {me.state.labels.length > 0 &&
              <div className={"flex flex-row justify-between mt-10 content-center items-center"}
                   style={me.state.blockActions ? {opacity: 0.4, pointerEvents: "none"} : {}}
              >
                  <button onClick={() => {
                    me.setState({
                      blockActions: true
                    }, () => {
                      me.props.api.asyncFetchBlobWithoutQueing(`/api/projects/data_sources/${me.props.dataSource.id}/download_dataset.zip`, {
                        method: "GET",
                        headers: {
                          "Accept": "application/zip"
                        }
                      }).then((blob: any) => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = `dataset_${me.props.dataSource.id}.zip`;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        me.setState({blockActions: false});
                      }, (_err) => {
                        me.setState({blockActions: false, error: _err});
                      });
                    });
                  }}>Датасет&nbsp;&nbsp;<i className={"fal fa-download"}/></button>
              </div>
          }
        </>
      }
    </div>
  </div>
  }
}