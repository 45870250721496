import React from "react";
import User from "../../../../../../common/models/User";
import {ApiEngine} from "api-engine";
import ProjectWidget from "../widget/ProjectWidget";
import ModalContainer from "../../../../../../../../common/layout/modal/ModalContainer";
import ProjectForm from "../form/ProjectForm";

interface ProjectsIndexProps {
  user: User;
  api: ApiEngine;
}

interface ProjectsIndexState {
  projects: any[];
  amount: null | number;
  addingProject: boolean;
}

export class ProjectIndex extends React.Component<ProjectsIndexProps, ProjectsIndexState> {
  mounted = false;

  constructor(_props: ProjectsIndexProps) {
    super(_props);
    this.state = {
      projects: [],
      amount: null,
      addingProject: false
    }
    this.getProjects = this.getProjects.bind(this);
  }

  componentDidMount() {
    const me = this;
    if (me.mounted) return;
    me.mounted = true;
    me.getProjects();
  }

  getProjects() {
    const me = this;
    me.props.api.asyncFetch("/api/users/projects/index", {}).then((_res) => {
      if (_res.projects) me.setState({projects: _res.projects, amount: _res.amount})
    });
  }

  render() {
    const me = this;
    return <>
      <div className={"flex row items-center content-center card"}>
        <h3 style={{margin: 0}}>Проекты</h3>
        {0 < me.state.projects.length &&
            <button className={"ml-auto"} onClick={() => {
              me.setState({addingProject: true})
            }}>              {me.state.addingProject}
                <i className={"fal fa-plus mr-12"}/>Создать</button>
        }
      </div>
      { 0 === me.state.projects.length &&
          <>
              <p className={"mt-15"}>Вы еще не создали ни одного проекта</p>
              <br/>
              <button onClick={() => {me.setState({addingProject: true})}}><i className={"fal fa-plus mr-12"}/>Создать</button>
          </>
      }
      <br/>
      <div className={"card w-50"}>
        { me.state.projects.map((_project, _projectIndex) => {
          return <ProjectWidget project={_project} key={`project-index-${_projectIndex}`}/>
        }) }
      </div>
      { me.state.addingProject && <ModalContainer onBackDropClick={() => {me.setState({addingProject: false}, () => {
        me.getProjects();
      })}}><ProjectForm callback={() => {me.setState({addingProject: false}, () => {
        me.getProjects();
      })}}/></ModalContainer> }
    </>
  }
}