import React from "react";
import "./styles/data_source_file_widget.scss";

export default class DataSourceFileWidget extends React.Component<any, any> {
  render() {
    const me = this;
    return <div className={"data-source-file-widget"}>
      <div className={"flex flex-column"}>
        <p className={"title"}>{me.props.dataSourceFile.title}</p>
        <p className={"description"}>{me.props.dataSourceFile.description}</p>
        <hr/>
        <button onClick={() => {document.location.href = `/cabinet/projects/${me.props.project.id}/data_sources/${me.props.dataSource.id}/data_source_files/${me.props.dataSourceFile.id}`}}>Разметить</button>
      </div>
      {/*<div className={"flex row justify-between mt-15"}>*/}
      {/*</div>*/}
      {me.props.dataSourceFile.file_url.toLowerCase().match(/\.(mp4|webm|mov|avi|wmv)$/) && 
        <video src={`${me.props.api.serverUrl}${me.props.dataSourceFile.file_url}`} controls/>
      }
      {me.props.dataSourceFile.file_url.toLowerCase().match(/\.(jpg|jpeg|png|gif|webp)$/) && 
        <img src={`${me.props.api.serverUrl}${me.props.dataSourceFile.file_url}`} alt={me.props.dataSourceFile.title}/>
      }
    </div>
  }
}