import React from "react";
import {ApiEngine} from "api-engine";
import "./styles/data_source_form.scss";
import { ApiContext } from "../../../../../../../api/contexts/ApiContext";

interface DataSourceFormProps {
  project: any;
  callback: any
}

interface DataSourceFormState {
  title: string | null
  description: string | null
  error: string | null
}

export default class DataSourceForm extends React.Component<DataSourceFormProps, DataSourceFormState> {
  constructor(_props: DataSourceFormProps) {
    super(_props);
    this.state = {
      title: null,
      description: null,
      error: null
    }
    this.createDataSource = this.createDataSource.bind(this);
  }

  createDataSource(api: ApiEngine) {
    const me = this;
    const projectParams = {
      project: this.props.project,
      title: this.state.title,
      description: this.state.description
    };
    try {
      api.asyncFetch("/api/projects/data_sources/new", {method: "POST", body: JSON.stringify(projectParams)}).then((_project) => {
        me.props.callback();
      } ).catch((_err) => {
        me.setState({error: JSON.stringify(_err)})
      });
    } catch (err) {
      me.setState({error: JSON.stringify(err)})
    }
  }

  render() {
    const me = this;
    return <div className={"project-form"} onClick={(_ev) => {_ev.stopPropagation();}}>
      <h3>Новый источник данных</h3>
      <hr/>
      <input onChange={(_ev: React.ChangeEvent<HTMLInputElement>) => {
        me.setState({title: _ev.currentTarget.value})
      }} placeholder={"Название"}/>
      <textarea onChange={(_ev: React.ChangeEvent<HTMLTextAreaElement>) => {me.setState({description: _ev.currentTarget.value})}} className={"mt-10"} placeholder={"Описание"}></textarea>
      <br/>
      <ApiContext.Consumer>
        {
          ({api, user}) => {
            return <>
              { api && <button className={"ml-auto"} onClick={() => {me.createDataSource(api)}}>Создать</button> }
            </>
          }
        }
      </ApiContext.Consumer>
    </div>;
  }
}