export default class User {
  lastName?: string;
  firstName?: string;
  middleName?: string;
  email?: string;
  cell?: string;
  canTrainLearningModels?: boolean = false;

  constructor(params: any) {
    this.lastName = params.lastName;
    this.firstName = params.firstName;
    this.middleName = params.middleName;
    this.email = params.email;
    this.cell = params.cell;
    this.canTrainLearningModels = params.canTrainLearningModels;
  }

  name() {
    if (!this.lastName && !this.firstName && !this.middleName) {
      return this.email;
    }
    return `${this.lastName} ${this.firstName}`
  }
}