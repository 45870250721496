import React from "react";
import "./sign-in.scss";
import {ApiContext} from "../../../api/contexts/ApiContext";
import {ApiEngine} from "api-engine";
import ApiProvider from "../../../api/providers/ApiProvider";

interface SignInProps {
    api: ApiEngine
}

interface SignInState {
}

export default class SignInContentWithToken extends React.Component<any, SignInState> {

    constructor(_props: any) {
        super(_props);
        this.state = {};
        this.sendDataUsingToken = this.sendDataUsingToken.bind(this);
    }

    componentDidMount() {
        const me = this;
        const token = new URLSearchParams(window.location.search).get("auth_code");
        if (token) {
            me.sendDataUsingToken(this.props.api, token);
        }
    }

    sendDataUsingToken(api: ApiEngine, token: string) {
        let me = this;
        me.setState({loading: true}, () => {
            api.asyncFetchWithRetries("/api/auto-auth/sign_in_by_token",
                {
                    method: "POST",
                    body: JSON.stringify({token: token})},
                1,
                false,
                0).then((_res) => {
                // alert(JSON.stringify(_res));
                localStorage.setItem("jwt", _res.jwt);
                document.location.href = "/cabinet";

            }).catch((_err) => {
                me.setState({loading: false, error: _err.toString()})
            })
        });
    }

    render() {
        const me = this;
        return <></>
    }
}