import React from "react";
import {ApiEngine} from "api-engine";
import User from "../../../../../../common/models/User";
import CircleSpinner from "../../../../../../../../common/loaders/spinners/cirlcle-spinner/CircleSpinner";
import LabelWidget from "../widget/LabelWidget";
import "./style/labels-index.scss";

interface LabelsIndexProps {
  project: any
  api:  ApiEngine
  user: User
}

interface LabelsIndexState {
  labels: null | any[],
}

export default class LabelsIndex extends React.Component<LabelsIndexProps, LabelsIndexState> {
  mounted = false

  constructor(_props: LabelsIndexProps) {
    super(_props);
    this.state = {
      labels: null
    };
    this.getLabels = this.getLabels.bind(this);
  }

  componentDidMount() {
    if (this.mounted) return
    this.mounted = true;
    this.getLabels();
  }

  getLabels() {
    const me = this;
    const url = `/api/projects/${me.props.project.id}/labels/index`;
    me.props.api.asyncFetch(url, {}).then((_res: any) => {
      if (_res.labels) {
        let tally = {} as any;
        for(let label of _res.labels) {
          if (tally[label.title]) tally[label.title] += 1
          else                    tally[label.title] = 1
        }
        let labels = [] as any[];
        for(let title of Object.keys(tally)) {
          labels.push({
            title: title,
            amount: tally[title]
          });
        }
        me.setState({labels: labels.sort((a, b) => {return b.amount - a.amount;})})
      } else me.setState({labels: []})
    })
  }

  render() {
    const me = this;
    if (null === me.state.labels) {
      return <div className={"ml-10"}>
        <h3>Распознаваемые признаки</h3>
        <br/>
        <CircleSpinner scale={0.2} />
      </div>
    }
    return <div className={"ml-10 card"}>
      <h3>Признаки</h3>
      <br/>
      <div className={"labels-index"}>
        { (0 === me.state.labels.length) &&
            <>
                <p>Ни одного распознаваемого признака еще не внесено в рамках проекта</p>
            </>
        }
        { me.state.labels.map((_label: any, _labelKey: number) => {
          return <LabelWidget
            title={_label.title}
            amount={_label.amount}
            key={`data-source-${_labelKey}`} />
        })
        }
      </div>
    </div>;
  }
}