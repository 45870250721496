import React, { FC, useState, ChangeEvent, FormEvent, useRef } from "react";
import LabelCard from "../../models/LabelCard";
import '../styles.scss';
import labelCard from "../../models/LabelCard";

interface AddLabelFormProps {
  addLabelCard: (newLabelCard: LabelCard, rectangleCoordinates: number[]) => Promise<void>;
  currentRectangleCoordinates: number[];
  project: any
  dataSource: any
  dataSourceFile: any
  active: boolean
  labels: any[]
}

const initState = {
  title: '',
}

const AddLabelForm: FC<AddLabelFormProps> = ({ labels, active, addLabelCard, currentRectangleCoordinates, project, dataSource, dataSourceFile }) => {
  const [newLabelCard, setNewLabelCard] = useState<{ title: string }>(initState);

  const formRef = useRef<HTMLFormElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewLabelCard({
      ...newLabelCard,
      [name]: value
    });
  }

  const sendLabel = (title: string | undefined = undefined) => {
    setIsLoading(true);
    if (!title) {
      alert('Название не может быть пустым');
      return;
    }
    addLabelCard(
      {
        title,
        id: Date.now(),
        coordinates: currentRectangleCoordinates,
      },
      currentRectangleCoordinates
    ).then(() => {
      setIsLoading(false);
    });
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { title } = newLabelCard;
    if (title) {
      sendLabel(title)
    }
    setNewLabelCard(initState);
  }

  // const handleReset = () => {
  //   setNewLabelCard(initState);
  //   if (formRef.current) {
  //     formRef.current.reset();
  //   }
  // }

  return (
    <form
      style={active && !isLoading ? {} : {opacity: 0.5, pointerEvents: "none"}}
      onSubmit={handleSubmit}
      ref={formRef}>
      {/*<p>{currentRectangleCoordinates.join("-")}</p>*/}
      <input
        name="title"
        type="text"
        placeholder="Название"
        onChange={handleChange}
        value={newLabelCard.title}
      />
      <div className={"hint"}>
        { labels.sort((a: any, b) => {
          const amntA = labels.filter((x) => {
            return x.title === a.title;
          }).length;
          const amntB = labels.filter((x) => {
            return x.title === b.title;
          }).length;
          return amntA > amntB ? -1 : 1;
        }).filter((x) => {
          if (!newLabelCard.title) return true;
          const aInB = newLabelCard.title.toLowerCase().indexOf(x.title.toLowerCase()) > -1;
          const bInA = x.title.toLowerCase().indexOf(newLabelCard.title.toLowerCase()) > -1;
          return aInB || bInA;
        }).map((_x) => {
          return _x.title;
        }).
        filter(function (v, i, self) {
          return i === self.indexOf(v);
        }).map((_x: any, _xIndex) => {
          return <span className={"button"}
                       key={`tooltip-${_xIndex}`}
                       onClick={() => {
                        //  setNewLabelCard({
                        //    ...newLabelCard,
                        //    title: _x
                        //  });
                        sendLabel(_x)
                        }
                       }
          >{_x}</span>
        })}
      </div>
      { newLabelCard.title && <button type="submit">
        Добавить
      </button> }
      {/* <button type="button" onClick={handleReset}>
        Отменить
      </button> */}
    </form>
  );
}

export default AddLabelForm;
