import React from "react";
import LabelCard from "../../../../models/LabelCard";
import SingleLabelCard from "../common/SingleLabelCard";
import CircleSpinner
  from "../../../../../../../../../../../../../../../../common/loaders/spinners/cirlcle-spinner/CircleSpinner";
import MulticircleSpinner
  from "../../../../../../../../../../../../../../../../common/loaders/spinners/multicircle-spinner/MulticircleSpinner";

interface ListByObjectModeProps {
  blockedLabelsIndexes: any
  deleteLabelCard: (card: any) => void
  labelCards: any[]
  rectangleCoordinates: any[]
  onSaveLabel: (newLabelCard: LabelCard, rectangleCoordinates: number[]) => void
  onLabelClick: (x: any) => void
  onHover: (title: string) => void
}

interface ListByObjectState {
  classificators: string[] | null
  chosenClassificator: string | null
}

export default class ListByClassificators extends React.Component<ListByObjectModeProps, ListByObjectState> {
  mounted: boolean = false
  constructor(_props: ListByObjectModeProps) {
    super(_props);
    this.state = {
      classificators: null,
      chosenClassificator: null
    };

    this.extractClassificators = this.extractClassificators.bind(this);
  }

  componentDidMount() {
    const me = this;
    if (me.mounted) return;
    me.mounted = true;
    me.extractClassificators();
  }

  extractClassificators() {
    const me = this;
    let classificators = [];
    for(let o of me.props.labelCards) {
      let classificator = o.title;
      if (classificators.indexOf(classificator) === -1)
        classificators.push(classificator);
    }
    me.setState({classificators: classificators.sort()});
  }

  render() {
    const me = this;
    if (null === me.state.classificators) return <MulticircleSpinner/>
    return <>
      { me.state.classificators.map((_classificator: string, _classificatorIndex: number) => {
        return <div key={`classifier-${_classificator}-${_classificatorIndex}`}>
            <button
              onClick={() => {
                me.setState({chosenClassificator: me.state.chosenClassificator ? (me.state.chosenClassificator === _classificator) ? null : _classificator : _classificator})
              }}
              style={{cursor: "pointer"}}
              className={"w-100 mb-10 flex flex-row content-center items-center"}>
              <i style={{opacity: 0.4}}  className={"fas fa-vector-square mr-auto pointer"}/>
              <p>{_classificator}</p>
            </button>

            { me.props.rectangleCoordinates.map((rectangleCoordinates: any[], index: number) => {
              const labelCard = me.props.labelCards[index];
              if (me.state.chosenClassificator !== labelCard.title) return <></>;
              if (_classificator !== labelCard.title) return <></>;
              return (
                <SingleLabelCard
                  blocked={me.props.blockedLabelsIndexes[labelCard.id]}
                  key={`label-in-list-by-classifiers-${_classificatorIndex}-${index}`}
                  deleteLabelCard={me.props.deleteLabelCard}
                  labelCard={labelCard}
                  rectangleCoordinates={rectangleCoordinates}
                  index={index}
                  isEditing={false}
                  onRectangleEdit={() => {}}
                  onSaveLabel={me.props.onSaveLabel}
                  onCancelEdit={() => {}}
                  onShow={me.props.onLabelClick}
                  onHover={me.props.onHover}
                />
              );
            })}
        </div>
      })}

    </>
  }
}